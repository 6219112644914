import React from "react"
import cmptStyles from "../styles/error404.module.css"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";
import { LocalImage } from "./shared/image"

const Error404 = ({ 
    content: { 
        error404: {
            title, 
            description,
            image 
        }
    }, 
    images
}) => {
    return (
        <>
            <Helmet>
                <html lang="en"/>
                <meta charset="utf-8"/>
                <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
                <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1,shrink-to-fit=no"/>
                <title>{ title }</title>
            </Helmet>
            <section className={ cmptStyles.wrapper } id="error404">
                <div className={ cmptStyles.container }>
                    <div className={ cmptStyles.inner }>

                        <div className={ cmptStyles.image }>
                            <LocalImage images={ images } name={ image.file } altText={ image.altText } />
                        </div>

                        <div className={ cmptStyles.text }>
                            <h1 className={ cmptStyles.title }>{ title }</h1>
                            <p className={ cmptStyles.description } dangerouslySetInnerHTML={{ __html:description }}></p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Error404

export const error404Query = graphql`
    fragment Error404 on DataJson {
        error404 {
            internal {
                type
            }
            title
            description
            image {
                altText
                file
            }
        }
    }
`