import React from "react"
import { graphql } from "gatsby"

import Header from "../components/header"
import Error404 from "../components/error404"
import Footer from "../components/footer"
import Support from "../components/shared/support"

const error404 = ({
    data: {
        header,
        content,
        footer,
        siteFurniture: {
            siteFurniture
        },
        images
    } 
}) => {
    return (
        <>
            <Header content={ header } siteFurniture={ siteFurniture } />
            <main role="main" id="#main-content">
                <Error404 content={ content } images={ images }/>
            </main>
            <Footer content={ footer } siteFurniture={ siteFurniture } />
            <Support/>
        </>
    )
}

export default error404

// eslint can't handle variables used in child fragments...
/* eslint-disable */
export const error404Query = graphql`
    query error404Page {
        header: dataJson {
            ...Header
        }
        content: dataJson {
            ...Error404
        }
        footer: dataJson {
            ...Footer
        }
        siteFurniture: dataJson {
            ...SiteFurniture
        }
        images: allFile( filter: { sourceInstanceName: { eq: "images" } } ) {
            edges {
                node {
                    ...LocalImageProperties
                }
            }
        }
    }
`
/* eslint-enable */